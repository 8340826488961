@mixin max-width-xxs
  @media (max-width: (320px))
    @content

@mixin max-width-xs
  @media (max-width: (490px))
    @content

@mixin max-width-sm
  @media (max-width: (576px))
    @content

@mixin min-width-sm
  @media (min-width: (576px))
    @content

@mixin max-width-md
  @media (max-width: (768px))
    @content

@mixin min-width-md
  @media (min-width: (768px))
    @content

@mixin max-width-lg
  @media (max-width: (992px))
    @content

@mixin min-width-lg
  @media (min-width: (992px))
    @content

@mixin max-width-xl
  @media (max-width: (1200px))
    @content

@mixin min-width-xl
  @media (min-width: (1200px))
    @content

@mixin max-width-xxl
  @media (max-width: (1400px))
    @content

@mixin min-width-xxl
  @media (min-width: (1400px))
    @content

@mixin max-width-variable($width)
  @media (max-width: ($width))
    @content

@mixin min-width-variable($width)
  @media (min-width: ($width))
    @content
