.management

	&-pages
		padding: 1rem
		box-shadow: 0 .375rem .75rem rgba(140,152,164,.075)
		border: .0625rem solid rgba(231,234,243,.7)
		border-radius: $border-radius
		margin-top: 1rem
		background-color: white
		display: flex
		justify-content: space-between
		align-items: center

		&-header
			font-weight: 500
			margin-bottom: 0

		&-link
			font-size: 0.9rem
			color: black
			text-decoration: none

			&:hover
				color: $link-color

	&-title
		font-weight: 500
		margin-bottom: 0

		&-block
			display: flex
			align-items: center
			justify-content: space-between
			margin-bottom: 1rem

		&-badge
			display: table
			padding: .4375em .75em
			font-size: 0.8125em
			font-weight: 500
			line-height: 1
			color: $white-color
			text-align: center
			white-space: nowrap
			vertical-align: baseline
			border-radius: .375rem

			&.rejected
				background-color: $red-color

			&.awaiting
				background-color: $warning-color
				color: black

			&.success
				background-color: $primary-color

			&.removed
				background-color: $gray-color

			&.draft
				background-color: $gray-color

	&-empty
		margin: 1.55rem .5rem
		text-align: left

	&-hello
		padding: 0 0 1rem 0

		&-text
			font-size: 1.25rem
			font-weight: 500
			margin-bottom: 0.5rem

	&-search-empty
		min-height: 150px
		display: flex
		justify-content: center
		align-items: center
		border-bottom: .0625rem solid rgba(231,234,243,.7)

		&-text
			font-weight: 500

	&-block
		padding: 1rem
		box-shadow: 0 .375rem .75rem rgba(140,152,164,.075)
		border: .0625rem solid rgba(231,234,243,.7)
		border-radius: $border-radius
		margin-top: 1rem
		background-color: white

		&-data
			display: flex

			&-item
				display: flex
				justify-content: space-between
				align-items: center

		&-link
			text-decoration: none

			& .management-block .separator
				border-color: $separator-color

		&-title
			color: #8c98a4
			font-size: 0.8rem
			text-transform: uppercase
			font-weight: 500

		&-count
			font-weight: 500
			color: black
			margin-right: 10px
			margin-bottom: 0

		&-text
			font-size: .8rem
			font-weight: 400
			margin-left: 5px
			color: $label-color
			margin-bottom: 0

	&-alert
		border-radius: $border-radius

		&-text
			margin-bottom: 0

	&-moderation
		box-shadow: 0 .375rem .75rem rgba(140,152,164,.075)
		border: .0625rem solid rgba(231,234,243,.7)
		border-radius: $border-radius
		background-color: white

		&.management
			margin-top: 2rem

		&-header
			padding: 1rem
			border-bottom: .0625rem solid rgba(231,234,243,.7)

			&-head
				border-bottom: .0625rem solid rgba(231,234,243,.7)
				background-color: $bg-color
				padding: .75rem 1rem
				font-size: 0.9rem
				color: $label-color

			&-title
				font-weight: 500
				margin-bottom: 0

		&-logo
			height: 30px
			width: 30px
			border-radius: 4px
			margin-right: 10px
			object-fit: cover

		&-data
			padding: 1rem
			border-bottom: 1px solid rgba(0,0,0,.125)

			&:hover
				background-color: $bg-color

			&.no-link
				background-color: $white-color

			&-block
				display: flex

			&-text
				font-size: 0.9rem
				margin-bottom: 15px

				&.first
					margin-bottom: 0

			&-header
				font-size: 0.9rem
				color: $label-color
				margin-bottom: 5px

			&-link
				font-size: 1.25rem
				margin-bottom: 0

	&-users
		box-shadow: 0 .375rem .75rem rgba(140,152,164,.075)
		border: .0625rem solid rgba(231,234,243,.7)
		border-radius: $border-radius
		background-color: white

		&-append

			&-block
				padding: 1rem
				display: flex
				align-items: center
				justify-content: space-between

			&-header
				font-weight: 500
				margin-bottom: 0

		&.management
			margin-top: 2rem

		&.object
			margin-top: 1rem
			border: none

		&-header
			padding: 1rem
			border-bottom: .0625rem solid rgba(231,234,243,.7)

			&-block
				display: flex
				justify-content: space-between
				align-items: center

				@include max-width-lg
					display: block

			&-search
				// max-width: 300px
				width: 100%

			&-head
				border-bottom: .0625rem solid rgba(231,234,243,.7)
				background-color: $bg-color
				padding: .75rem 1rem
				font-size: 0.9rem
				color: $label-color

			&-title
				font-weight: 500
				margin-bottom: 0

				&.object
					font-size: 0.9rem

		&-logo
			height: 30px
			border-radius: 50%
			margin-right: 10px

			&.title
				margin-right: 5px

		&-data
			padding: 1rem
			border-bottom: 1px solid rgba(0,0,0,.125)

			&:hover
				background-color: $bg-color

			&-text
				font-size: 0.9rem
				margin-bottom: 0
				margin-bottom: 15px

				&.first
					margin-bottom: 0

			&-header
				font-size: 0.9rem
				color: $label-color
				margin-bottom: 5px

		&-footer
			padding: 1rem

			&-link
				font-size: 0.9rem
				color: black
				text-decoration: none

				&:hover
					color: $link-color

			&-search-text
				font-size: 0.9rem
				color: $label-color
				margin-bottom: 5px
				text-decoration: none

				&:hover
					color: $link-color

	&-footer

		&-link
			font-size: 0.9rem
			color: $label-color
			text-decoration: none

			&:hover
				color: $link-color

	&-approval
		display: flex
		justify-content: space-between
		align-items: center
		background-color: $white-color
		border-radius: $border-radius
		padding: 1rem
		margin-top: 1rem

		&-block
			display: flex
			flex-direction: column

			&.flex-none
				flex: none

		&-text
			margin-bottom: 0
			font-size: 0.9rem
			font-weight: 500

		&-description
			margin-bottom: 0
			font-size: 0.75rem
			color: $label-color
			// font-weight: 500

		&-link
			font-size: 0.75rem
			color: $label-color
			text-decoration: none
			// margin-top: 0.375rem

			&:hover
				color: $red-color

		&-button
			min-width: 178px

	&-reject

		&-button
			min-width: 201px

@media(max-width: 992px)

	.management

		&-approval
			flex-direction: column

			&-block
				text-align: center
				margin-bottom: 0.5rem

		&-users-data-text
			margin-bottom: 1rem

		&-users-logo
			margin-top: 5px

		&-moderation-logo
			margin-top: 5px

@media(min-width: 992px)

	.management

		&-moderation-data-text
			margin-bottom: 0

		&-users-data-text
			margin-bottom: 0
