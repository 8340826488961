.control

    &-object
        background-color: $bg-color

        &-link
            text-decoration: none
            color: black

            &:hover
                color: black

    &-100
        min-height: calc(100vh - 110px)

    &-wrapper
        display: flex
        width: 100%
        align-items: stretch

    &-content
        width: 100%
        padding: 30px 15px 30px 295px
        background-color: $bg-color
        min-height: 100vh

    &-sidebar
        min-width: 280px
        max-width: 280px
        background-color: $white-color
        min-height: 100vh
        transition: all 0.3s
        z-index: 999
        position: fixed

        &_content
            text-align: center

    &-hr
        background: radial-gradient(ellipse at center, white 0%, #353a3f 75%)
        height: 1px
        border: none
        margin: 10px 0

    &-user
        display: flex
        align-items: center
        padding: 20px 0 10px

        & i
            font-size: 1.5rem
            margin-right: 10px
            margin-left: 7px

        & p.user
            font-size: 13px
            margin-bottom: 0
            font-weight: 500

        & p.statistic
            font-size: 8px
            margin-bottom: 0

        &-icon
            font-size: 1.75rem

    &-menu
        display: flex
        flex-direction: column
        text-align: left

    &-link
        padding: 10px
        color: $menu-label-color
        font-size: 14px
        text-decoration: none

        &:hover
            color: $white-color
            text-decoration: none
            background-color: $link-color
            border-radius: 4px

    &-exit
        margin-bottom: 275px
        color: white
        background-color: transparent
        border: none
        font-size: 14px

    &-button, &-button-close
        background-color: transparent
        border: none
        font-size: 22px
        color: black

        &:active
            color: black

        &-close
            position: absolute
            top: 5px
            right: 4px
            padding: 15px

    &-topbar
        top: 0px
        left: 0px
        width: 100%
        height: 60px
        background-color: white
        position: fixed
        box-shadow: 0px 5px 12px -10px #333
        z-index: 999

    &-data-row
        border-bottom: 1px solid rgba(0,0,0,.125)
        padding-top: 1rem

        &.product:hover
            background-color: lighten(black, 90%)

        &_text
            font-size: 14px

@media(min-width: 992px)

    .control-sidebar
        margin-left: 0px

@media (max-width: 992px)

    .control

        &-100
            height: calc(100vh - 60px)

        &-content
            padding: 30px 0 30px 0
            margin-top: 60px

        &-sidebar
            margin-left: -280px
            position: fixed
            box-shadow: 0px 5px 12px -10px #333

            &.active
                margin-left: 0

        &-user
            display: flex
            align-items: center
            padding: 0

            & img.img
                height: 30px
                margin-right: 10px
                color: black

            & p.user
                font-size: 12px
                color: black
                margin-bottom: 0

            & p.statistic
                color: black

        &-exit
            margin-bottom: 205px
