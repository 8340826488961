$bg-color: #f8f8f8
$white-color: #fff
$gray-color: #8E8F94
$gray-color-700: #646468
$primary-color: #17afd1
$second-color: #A0136C
$text-color: #1B86B8
$hover-color: #0E6187
$active-color: #0E6187
$separator-color: #DBDCE4
$light-color: #F8F8F8
$header-text-color: #333335
$acent-color: #2798CD
$menu-label-color: #152242
$link-color: #5b647a
$red-color: #cd424a
$warning-color: #ffcc00
$label-color: #74717b

$border-radius: .5rem

$box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.2)

$animation: loading 1s linear infinite alternate
