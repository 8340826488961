.login-form
    height: 100vh
    align-items: center

.admin

    &-swiper
        height: auto !important

    &-title
        font-size: 1.1rem
        font-weight: 500
        border-bottom: .0625rem solid rgba(231,234,243,.7)
        padding-bottom: 1.25rem

    &-background
        background-color: #333

.btn:focus, .form-control:focus, .dropdown-toggle:focus, .custom-select:focus, .swal2-popup:focus, .swal2-close:focus
    outline: none !important
    -webkit-box-shadow: none
    box-shadow: none

.separator
    margin: 0
    border: 0
    border-top: 1px solid
    border-color: $separator-color !important

.button

    &-delete
        color: red
        padding: 0
        display: block
        padding: 0 10px
        margin-top: -2px

        &:hover
            color: darken(red, 10%)

    &-edit
        font-size: 14px
        padding: 0 10px
        // margin-top: -1px
        color: #5b647a

    &-toggle
        color: #5b647a
        font-size: 18px
        padding: 0 10px
        margin-top: -2px

        &.activate
            color: green

    &-view
        padding: 0
        margin-top: -2px

.image-delete
    margin: 20px
    height: 70px

.no-decoration
    text-decoration: none

    &:hover
        text-decoration: none

.admin-input

    &::before
        content: 'Выбрать'
        padding: 10px 30px
        margin-left: 2px
        background-color: #287ef6
        border: 0
        border-radius: 10px
        color: white
        font-size: 14px
        cursor: pointer

    &:hover::before
        background-color: #226bd2
        cursor: pointer

    &::-webkit-file-upload-button
        visibility: hidden

.custom-select:focus
    -webkit-box-shadow: none
    box-shadow: none
